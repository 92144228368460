<template>
  <div class="page">
    <div v-if="getModuleConfig('thanks_page.step_section.enable_step_section')" class="p-3">
      <SharedStep :steps="getModuleConfig('thanks_page.step_section.steps')" />
    </div>
    <div v-if="getModuleConfig('thanks_page.form_title')" class="page__title">
      {{ getModuleConfig("thanks_page.form_title") }}
    </div>
    <div v-if="getModuleConfig('thanks_page.form_desc')" class="page__desc">
      {{ getModuleConfig("thanks_page.form_desc") }}
    </div>

    <div class="page__content" v-if="thankContent" v-html="thankContent"></div>

    <img
      v-for="image in contentImages"
      :src="image.image_url"
      :key="image.order"
      class="w-100"
    />

    <div class="page__button s-space-y-4">
      <MemberButton
        :button-text="getModuleConfig('thanks_page.button_text')"
        :button-url="getModuleConfig('thanks_page.button_url')"
        :type="getModuleConfig('thanks_page.button_action')"
      ></MemberButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import registerMixin from "@/mixins/liff/register";
import SharedStep from "@/components/Page/Liff/Shared/Steps";

export default {
  mixins: [registerMixin],
  components: {
    SharedStep,
    MemberButton,
  },
  data() {
    return {
      content: null,
    };
  },
  computed: {
    ...mapGetters({
      hasIntended: "route/hasIntended",
    }),
    contentImages() {
      let contentImages = this.getModuleConfig("thanks_page.content_images") || []
      contentImages.sort((a, b) => a.order - b.order)
      return contentImages
    },
    thankContent() {
      return this.getModuleConfig("thanks_page.content") ?? false;
    },
    showPage() {
      return this.getModuleConfig('thanks_page.step_section.enable_step_section')
        || this.getModuleConfig('thanks_page.form_title')
        || this.getModuleConfig('thanks_page.form_desc')
    },
  },
  async created() {
  },
  beforeMount() {
  },
  methods: {
    ...mapActions({
      intended: "route/intended",
    }),
    redirectToIntended() {
      this.intended();
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  max-width: 425px;
  margin: auto;
}

.item {
  display: flex;
  margin-bottom: 16px;

  &__text {
    color: var(--liff-primary_text_color);
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 8px;
  }
}
</style>
